body {
    background-color: #252525;
    color: #f5f5f5;
}

.hide {
    display: none;
}

.row {
    margin:0;
}
/*
.btn {
    background-color: #736767;
    color:#f5f5f5;
}
.btn:hover {
    background-color: #737373;
}
*/

p {
    margin-bottom: 0;
}
.warning {
    font-size: 14px;
    color:red;
}
.success {
    font-size: 14px;
    color:green;
}

input, select, textarea {
    background-color: #000!important;
    border: #f5f5f5!important;
    color:#f5f5f5!important;
}
.alert {
    padding:8px;
}
input:disabled {
    background-color: #e9ecef!important;
    opacity: 0.7!important;
    color: #252525!important;
}
.text-muted {
    display: block;
    margin-bottom: 8px;
}
.modal input, .modal select, .modal textarea {
    margin-bottom: 12px;
}
table {
    color:#f5f5f5!important;
}
table td {
    color:#f5f5f5!important;
}
.modal-content {
    background-color: #252525;
    border: 1px solid #f5f5f5;
}
.page table,.modal table {
    margin-top: 15px;
}
.page table th,.modal table th {
    font-size: 12px;
}
.page table .btn,.modal table .btn {
    margin-bottom: 8px;
    margin-right: 8px;
}
.system-success {
    text-align: center;
    font-weight: 700;
    color:green;
}
.modal .modal-title {
    font-size:16px;
}
.modal .btn-sm {
    font-size: 14px;
    padding:0px 8px;
}
table .td-amount-of-sum {
    font-weight: 700;
}




.page.login {
    padding-top:25%;
}
.page.login form {
    margin-top:25px;
}
.page.login .btn {
    margin-top: 15px;
    margin-bottom: 15px;
}
.page.login .warning {
    text-align: center;
}



.header {
    border-bottom: 1px solid #737373;
    min-height: 73px;
}
.header p.position {
    font-size: 12px;
}
.header .left-panel {
    border-right: 1px solid #737373;
    position: absolute;
    width: 230px;
}
.header .left-panel .short-info {
    margin: 15px 25px;
}
.header .left-panel img {
    max-height: 30px;
    margin: 15px 25px;
}

.header .header-data {
    margin-left: 230px;
}
.header .header-data h3 {
    padding-top:15px;
    font-weight: 400;
    margin-left: 15px;
}
.header .header-data .logout {
    text-align: right;
    padding-top: 20px;
}

.menu {
    border-right: 1px solid #737373;
    position: absolute;
    width: 230px;
    height: 100%;
}
.menu .title p {
    margin-left: 25px;
    font-size: 12px;
    padding:8px 0;
    color: #737373;
    text-transform: uppercase;
}
.menu ul {
    list-style: none;
    padding-left: 0;
}
.menu ul li a {
    text-decoration: none;
    color:#f5f5f5;
    padding:6px 15px;
    display: block;
    border-bottom: 1px solid #737373;
}
.menu ul li a.active {
    background-color: #000;
}
.menu ul li a.active {
    border-bottom: 1px solid #000;
}
.menu ul li:hover {
    background-color: #402D2D;
}
.menu ul li a i {
    margin-right: 4px;
    font-size: 14px;
}


.main {
    margin-left: 230px;
    padding:15px;
}



.page.profile input {
    margin-bottom: 15px;
}
.page.profile .warning {
    margin-top: 15px;
}





.page.abonements .control .btn {
    margin-top:8px;
    margin-right:8px;
}
.page.abonements table {
    margin-top: 15px;
}
.page.abonements table .btn {
    margin-bottom:8px;
}







.page.teachers table {
    margin-top: 15px;
}
.page.teachers table th {
    font-size: 12px;
}
.page.teachers table .btn {
    display: block;
    margin-bottom: 8px;
}



.modalEditTeacher input, .modalEditTeacher select {
    margin-bottom: 12px;
}
.modalEditTeacher .photo {
    margin-bottom: 12px;
}
.modalEditTeacher .photo img {
    border-radius: 50%;
    height: 120px;
    margin-bottom: 12px;
}
.modalEditTeacher .photo .btn {
    margin-bottom: 12px;
}
.modalEditTeacher .warning, .modalEditTeacher .success {
    margin-top: 12px;
}
.modalEditTeacher table {
    margin-top:12px;
}
.modalEditTeacher table .btn {
    margin-right:8px;
}






.page.users .filter-global {
    
}
.page.users .filter-global p {
    text-transform: uppercase;
    font-size: 14px;
    color: #737373;
}
.page.users .filter-global ul {
    list-style: none;
    padding-left: 0;
    margin-top: 12px;
}
.page.users .filter-global ul li {
    padding:8px 12px;
    border-bottom: 1px solid #402D2D;
    cursor: pointer;
    border-radius: 12px;
}
.page.users .filter-global ul li span {
    float: right;
}
.page.users .filter-global ul li.active {
    background-color: #000;
}
.page.users .filter-global ul li:hover {
    background-color: #402D2D;
}

.page.users .filter .results-pagination {
    margin-top:25px;
}
.page.users .filter .results-pagination .find {
    font-size:20px;
}
.page.users .filter .results-pagination ul {
    list-style: none;
    padding-left: 0;
    float:right;
}
.page.users .filter .results-pagination ul li {
    float: left;
    margin-right:2px;
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid #402D2D;
    cursor: pointer;
}
.page.users .filter .results-pagination ul li.active {
    background-color: #000;
}
.page.users .filter .results-pagination ul li:hover {
    background-color: #402D2D;
}
.page.users .filter .results-pagination ul li.disabled {
    cursor: not-allowed;
    background-color: transparent;
}
.page.users .filter .results-pagination .find-count {
    float: left;
}





.user-search-preview {
    border-radius: 8px;
    border: 1px solid #402D2D;
    padding:12px;
    cursor: pointer;
    margin-bottom: 8px;
}
.user-search-preview:hover {
    background-color: #402D2D;
}
.user-search-preview img {
    max-height: 100px;
    border-radius: 50%;
    float: left;
    margin-right: 12px;
}
.user-search-preview p span {
    color: #737373;
}
.user-search-preview ul {
    list-style: none;
    padding-left: 0;
    font-size: 14px;
}
.user-search-preview ul.short-info {
    margin-top: 12px;
    margin-bottom: 8px;
}
.user-search-preview ul.line {
    margin-bottom: 0;
}
.user-search-preview ul.line li {
    display: inline-block;
    min-width: 30%;
}
.user-search-preview ul.line li i {
    margin-right: 8px;
}






.page.user {
    margin-top:25px;
    margin-bottom:150px;
}
.page.user .poster {
    border-radius: 50%;
}
.page.user .preview h4 {
    margin:25px 0;
    text-align: center;
}
.page.user .preview p {
    margin-top:12px;
    font-size: 14px;
}
.page.user .preview p.title {
    margin-top:12px;
    font-size: 12px;
    color: #737373;
    text-transform: uppercase;
}
.page.user .preview i {
    margin-right:4px;
}
.page.user .preview .btn {
    margin: 15px 0;
}
.page.user .nav-link {
    font-size:14px;
    color:#f5f5f5;
    padding:6px 12px;
}
.page.user .nav-link.active {
    background-color: #000;
}
.page.user .tab-pane {
    margin-top: 12px;
}
.page.user .schedule p {
    font-size: 14px;
    margin-bottom: 12px;
}
.page.user .schedule p span {
    font-size: 12px;
}
.page.user .schedule .btn {
    display: block;
    margin-bottom: 8px;
}





.subview.abonements-for-buying .abonement-for-buying {
    margin-bottom: 8px;
}
.subview.abonements-for-buying h6 {
    margin-top: 12px;
    margin-bottom: 12px;
}






.subview.user-profile input {
    margin-bottom: 8px;
}


.subview.purchase-abonements {
    border-radius: 8px;
    border: 2px solid green;
    padding:8px;
    font-size: 14px;
}
.subview.purchase-abonements .purchase-abonement {
    margin-bottom: 4px;
}
.subview.purchase-abonements .purchase-abonement span {
    font-weight: bold;
}
.purchase-abonement .btn-sm {
    font-size: 12px;
    padding: 2px 4px;
    margin-top: -2px;
}





.modal-visit-new .modal-title {
    font-size:16px;
}
.modal-visit-new .purchase-abonement {
    margin-top: 8px;
}



.visit-lite {
    margin-bottom: 8px;
}
.visit-lite .btn {
    margin-top: -3px;
}





.page.teacher-salaries .filter-block {
    border: 1px solid #737373;
    border-radius: 4px;
    padding:25px;
    margin-bottom: 25px;
}
.page.teacher-salaries .div-form {
    padding: 0;
}
.page.teacher-salaries .div-form form {
    
    margin-top: 12px;
    padding-right: 12px;
}
.page.teacher-salaries .div-form form input {
    margin-right: 12px;
}
.page.teacher-salaries .div-form .btn {
    margin-top: 20px;
}
.page.teacher-salaries table tr td font {
    display: inline-block;
    font-size: 12px;
    color: #737373;
}






.modal-teacher-salary-info .btn {
    margin-bottom:8px;
}
.modal-teacher-salary-info table {
    font-size: 14px;
}






.page.lessons .filter-block {
    border: 1px solid #737373;
    border-radius: 4px;
    padding:25px;
    margin-bottom: 25px;
}
.page.lessons .div-form {
    padding: 0;
}
.page.lessons .div-form form {
    
    margin-top: 12px;
    padding-right: 12px;
}
.page.lessons .div-form form input {
    margin-right: 12px;
}
.page.lessons .div-form .btn {
    margin-top: 20px;
    margin-right: 8px;
}
.page.lessons table th {
    text-align: center;
}
.page.lessons table th font {
    font-size:10px;
}
.page.lessons table .visit-lesson {
    text-align: center;
    cursor: pointer;
}
.page.lessons table .visit-lesson font {
    display: block;
}
.page.lessons table .visit-lesson:hover {
    background-color: #402D2D;
}
.page.lessons table .tbody-footer-title {
    font-size: 14px;
}
.page.lessons table .tbody-footer-title p {
    font-weight: 700;
    margin-bottom:0;
}
.page.lessons table .tbody-footer-title font {
    display: block;
    font-size: 10px;
}






.modal-purchase-abonements-history .modal-dialog, .modal-teacher-salary-info .modal-dialog {
    min-width: 80%;
}
.modal-purchase-abonements-history table {
    font-size: 14px;
}





.connection-user-to-dance-group-block {
    border: 1px solid #402D2D;
    border-radius: 4px;
    margin-top: 8px;
    padding:12px;
    cursor: pointer;
}
.connection-user-to-dance-group-block:hover {
    background-color: #402D2D;
}
.connection-user-to-dance-group-block font {
    color:#737373;
    font-size: 14px;
}



.admin-search-preview {
    border-radius: 8px;
    border: 1px solid #402D2D;
    padding:12px;
    cursor: pointer;
    margin-bottom: 8px;
}
.admin-search-preview:hover {
    background-color: #402D2D;
}
.admin-search-preview img {
    max-height: 100px;
    border-radius: 50%;
    float: left;
    margin-right: 12px;
}
.admin-search-preview ul {
    list-style: none;
    padding-left: 0;
}
.admin-search-preview p {
    color: #737373;
}
.admin-search-preview ul.short-info {
    margin-top: 25px;
    margin-bottom: 8px;
}
.admin-search-preview ul.short-info li {
    min-width: 30%;
}
.admin-search-preview ul.short-info li i {
    margin-right: 4px;
}






.modal .admin {
    margin-top:25px;
    margin-bottom:150px;
}
.modal .admin .nav-link {
    font-size:14px;
    color:#f5f5f5;
    padding:6px 12px;
}
.modal .admin .nav-link.active {
    background-color: #000;
}
.modal .admin .tab-pane {
    margin-top: 12px;
}
.modal .admin .poster {
    border-radius: 50%;
}
.modal .admin .preview h4 {
    margin:25px 0;
    text-align: center;
}
.modal .admin .preview p {
    margin-top:12px;
    font-size: 14px;
}
.modal .admin .preview p.title {
    margin-top:12px;
    font-size: 12px;
    color: #737373;
    text-transform: uppercase;
}
.modal .admin .preview i {
    margin-right:4px;
}
.modal .admin .preview .btn {
    margin: 15px 0;
}








.page.cashbox .filter-block {
    border: 1px solid #737373;
    border-radius: 4px;
    padding:25px;
    margin-bottom: 25px;
}
.page.cashbox .div-form {
    padding: 0;
}
.page.cashbox .div-form form {
    margin-top: 12px;
    padding-right: 12px;
}
.page.cashbox .div-form form input {
    margin-right: 12px;
}
.page.cashbox .div-form .btn {
    margin-top: 20px;
}
.page.cashbox .result {
    text-align: right;
    font-size: 20px;
    text-transform: uppercase;
    color:#737373;
}
.page.cashbox .result p {
    border-top: 1px solid #737373;
    padding: 12px 0;
}
.page.cashbox .result font {
    font-weight: 700;
}




.page.analytics .filter-block {
    border: 1px solid #737373;
    border-radius: 4px;
    padding:25px;
    margin-bottom: 25px;
}
.page.analytics .div-form {
    padding: 0;
}
.page.analytics .div-form form {
    margin-top: 12px;
    padding-right: 12px;
}
.page.analytics .div-form form input {
    margin-right: 12px;
}
.page.analytics .div-form .btn {
    margin-top: 20px;
}




.page.dashboard .filter-block {
    border: 1px solid #737373;
    border-radius: 4px;
    padding:10px;
    margin-bottom: 25px;
}
.page.dashboards .div-form {
    padding: 0;
}
.page.dashboard .div-form form {
    
    margin-top: 12px;
    padding-right: 12px;
}
.page.dashboard .div-form form input {
    margin-right: 12px;
}
.page.dashboard .div-form .btn {
    margin-top: 42px;
}
.page.dashboard .ps {
    font-size: 12px;
    margin-top:-12px;
    margin-bottom: 12px;
    color: #737373;
}





.page.dashboard .analytics-block {
    border-radius: 4px;
    padding:15px;
    margin-bottom: 15px;
    /*cursor: pointer;*/
}
.page.dashboard .analytics-block:hover {
    /*opacity: 0.9;*/
}
.page.dashboard .analytics-block p {
    margin-bottom: 0;
}
.page.dashboard .analytics-block .icon {
    font-size: 42px;
    background-color: rgba(255, 255, 255, 0.9);
}
.page.dashboard .analytics-block .icon {
    color: #252525;
    border-radius: 4px;
    text-align: center;
    display: inline;
    padding:0 16px;
    margin-right: 15px;
    float: left;
}
.page.dashboard .analytics-block .desc {
    margin-top: -2px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    font-weight: 700;
}
.page.dashboard .analytics-block.sm .desc {
    font-size: 10px;
}
.page.dashboard .analytics-block .value {
    font-size: 40px;
    margin-top: -8px;
    font-weight: 500;
}
.page.dashboard .analytics-block.sm .value {
    margin-top: 0px;
    font-size: 30px;
}
.page.dashboard .analytics-block font.right {
    float: right;
}
.page.dashboard .analytics-block .value i {
    margin-right: 4px;
}
.page.dashboard .analytics-block.panel-success-alt {
    background-color: #4cae4c;
}
.page.dashboard .analytics-block.panel-info-alt {
    background-color: #428bca;
}
.page.dashboard .analytics-block.panel-dark-alt {
    background-color: #4e5154;
}
.page.dashboard .analytics-block.panel-warning-alt {
    background-color: #f0ad4e;
}
.page.dashboard .analytics-block.panel-warning-alt .desc,.page.dashboard .analytics-block.panel-warning-alt .value {
    color:#252525;
}
.page.dashboard .analytics-table p {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
}
.page.dashboard .analytics-table p span {
    font-size: 10px;
    color: #737373;
}
.page.dashboard .analytics-table table {
    font-size: 12px;
}
.page.dashboard .analytics-table tr td span {
    color: #737373;
}
